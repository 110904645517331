.clientFinancialSummary {
  display: flex;
  flex-direction: column;

  .loading {
    margin-top: 20px;
  }

  .noResults {
    font-weight: 600;
    padding: 30px;
    text-align: center;
  }

  .filters {
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: row;

    .gridInput {
      .input {
        border: 1px solid #c4c4c4;
        font-size: 12px;
        padding: 8px;
        border-radius: 4px;
        width: 100%;
      }
      .input::placeholder {
        color: #c4c4c4;
      }
    }
  }
}
