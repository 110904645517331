.exportButton {
  border: 1px solid #c4c4c4 !important;
  font-size: 12px;
  margin-left: 10px;
  text-transform: none;

  .icon {
    margin-right: 6px;
    font-size: 18px;
  }

  .iconSpin {
    margin-right: 6px;
    font-size: 18px;
    position: relative;
    animation: spin 1s infinite linear;
  }
}

@media screen and (max-width: 770px) {
  .exportButton {
    margin-top: 10px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
